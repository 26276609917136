@import './variables';
// Styling dots
.owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-stage {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}
.owl-item {
  float: initial !important;
}

.owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;

  .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;

    span {
      width: $dot-width;
      height: $dot-height;
      margin: $dot-margin;
      background: $dot-background;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: $dot-rounded;
    }

    &.active,
    &:hover {
      span {
        background: $dot-background-active;
      }
    }
  }
}

.dots-over .owl-dots{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.dots-over .owl-nav.disabled{
  display: none;
}