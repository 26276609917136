/* Media query manager */
@mixin responsive($breakpoint) {
  @if $breakpoint == phone { 
    @media only screen and (max-width: 37.25em) { // 500px
      @content
    };
  }

  @if $breakpoint == tablet { 
    @media only screen and (max-width: 61.25em) { // 980px
       @content
    }; 
 }

 @if $breakpoint == tab_port {
  @media only screen and (max-width: 74.9em) {   // 1184px 
     @content
  }; 
}

@if $breakpoint == lg_desktop {
  @media only screen and (max-width: 102em) {   // 1633px 
     @content
  }; 
}
}


