$color-red: #c9252d;
$color-red-background: #bf1e2e;

$color-dark: #000;
$modal-background-color: #000;

$color-white: #fff;

$color-category-professional: #DC5445;
$color-category-financial: #008751;
$color-category-personal: #CA1F40;
$color-category-subscription: #5C5D5F;
$color-category-marketplace: #6610F2;
$color-category-merchandise: #007BFF;
$color-category-promogear: #fffafa;
$color-category-lifetraining: #00A4E3;
$color-category-autoshippro: #ab7b0c;
$color-category-salesaid: #111;