@import "../abstracts/variables";
@import "../abstracts/functions";

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.suggestion-img {
  width: 6.25rem;
  height: 6.25rem;

  @include responsive(phone) {
    width: 5rem;
    height: 5rem;
  }
}

.suggestion-product-name {
  align-self: center;
  padding-left: 15px;
  font-size: 18px;

  @include responsive(phone) {
    font-size: small;
    padding-right: 10px;
  }

  @include responsive(tablet) {
    padding-right: 10px;
  }
}

.suggestion-category-text {
  align-self: flex-end;
  font-weight: bold;
  font-size: large;
  white-space: nowrap;

  @include responsive(phone) {
    font-size: small;
    margin-right: -10px;
  }

}
.suggestion-header {
  display: grid;
  grid-template-columns: 1fr 10fr;
  height: 5rem;
}

.suggestion-container {
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  @include responsive(phone) {
    font-weight: normal;
    font-size: initial;
  }
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgb(41, 41, 41);
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #0C7EAF;
  color: #fff;
}

.search-main-content {
  padding-top: 5rem;
  padding-bottom: 5rem;
  height: 100%;
  color: $color-white;

  @include responsive(phone) {
    padding-bottom: 0.625rem;
    padding-top: 3.75rem;
  }
  .category-view-img__title {
    font-size: 0.9375rem;
    color: #fff;

    @include responsive(phone) {
      font-size: 0.75rem;
      width: 5rem;
      text-align: center;
    }
  }

  &__search-bar {
    width: 100%;
    font-weight: 100;
    background-color: #000;
    height: 64px;
    border: 0;
    font-size: 48px;
    border-bottom: 1px solid;
    border-color: rgb(132, 131, 131);
    color: #fff;
    margin-bottom: 0;
    display: flex;
  }

  &__category-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &>* {
      // padding-bottom: 4rem;
      margin-bottom: 2.5rem;
      cursor: pointer;
      color: #177dff!important; 
    }
    &>p {
      padding-left: 0.625rem;
      font-size: 1.25rem;
      color: #177dff!important; 
    }
  }

  &__headers {
    display: flex;
    padding-top: 1.25rem;
    justify-content: space-between;
    &>p {
      color: #177dff!important; 
      font-size: 1.25rem;
      cursor: pointer;
    }
  }

  &__filter-container {
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    top: 10.3125rem;
    color: #fff;
    text-align: left;
    font-size: 1.25rem;
    z-index: 10000000;
    border: none;
    padding-left: 0.9375rem;
    // height: 10;
    padding-bottom: 1.25rem;

    // background-color: red;

    .category-dropdown {
      padding-top: 0.3125rem;

      .dropdown-item:hover {
        background-color: rgb(18, 18, 18);
      }

      &__menu {
        background-color: rgb(18, 18, 18);
        &>.form-check {
          padding-right: 1.25rem;
          &>label {
            padding-left: 1.25rem;
          }
        }
        &.form-check-input
        &>.form-check>label>input {
          margin-right: 1.875rem;
        }
    
        .input-filter-item {
          color: #fff;
          font-size: 1.25rem;
        }
      }
    }

    &>h1 {
      padding-top: 0.625rem;
      padding-right: 1.25rem;
    }

    &>.form-check {
      padding-right: 1.25rem;
      &>label {
        padding-left: 1.25rem;
      }
    }
    &.form-check-input
    &>.form-check>label>input {
      margin-right: 1.875rem;
    }

    .input-filter-item {
      color: #fff;
      font-size: 1.25rem;
    }
  }

  &__search-result {
    padding-top: 1.25rem;

    @include responsive(phone) {
      padding-top: 2.5rem;
    }

    &>div>.top-products>.BrainhubCarousel__trackContainer>ul>li>p {
      color: #fff;
      font-size: 0.9375rem;
      padding-top: 0.625rem;
    }
  }

  &__top-products-title {
    color: #fff;
    font-size: 1.25rem;
    padding-bottom: 1.25rem;
  }

  &__categories {
    padding-top: 3.125rem;

    &>* {
      color: #fff;
    }
  }
  .message-error {
    padding-top: 3.125rem;
    padding-bottom: 25rem;
    font-size: 2.1875rem;
    color: #fff;
    text-align: center;
  }

  .message-loading {
    padding-top: 3.125rem;
    padding-bottom: 25rem;
    font-size: 2.1875rem;
    color: #fff;
    text-align: center; 
  }
  .react-autosuggest {
    &__container {
      width: 100%;
    }

    &__input {
      width: 100%;
      font-weight: 100;
      background-color: #000;
      height: 64px;
      border: 0;
      font-size: 48px;
      border-bottom: 1px solid;
      border-color: rgb(132, 131, 131);
      color: #fff;
      margin-bottom: 0;
      display: flex;
      @include responsive(phone) {
        font-size: 25px;
      }
    }
  }
}

#search-top-products {
  height: 12.5rem;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;

  @include responsive(phone) {
    // height: 12rem;
  }
}

.form-check [type=checkbox]:checked,
.form-check [type=checkbox]:not(:checked) {
  left: 0 !important;
  background-color: red !important;
  height: 1.25rem;
  padding-right: 1.25rem;
}


.lds-ring {
  display: inline-block;
  position: relative;
  padding-top: 1.875rem;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
