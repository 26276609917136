@import './variables';
@import 'react-h5-audio-player/src/styles.scss';

.rhap_container {
  padding: 0;
}

.rhap_controls-section{
  position: relative;
  align-items: stretch;
}
.rhap_play-pause-button {
  font-size: 40px;
  width: 35px;
  height: 35px;

}
.rhap_stacked .rhap_controls-section{
  margin-top: 0;
}


.rhap_time{
  font-size: 14px;
}



.rhap_progress-bar{
  background-color: #a4a4a4;
}
.rhap_download-progress, .rhap_progress-indicator{
  display: none;

}
.rhap_main {
  > .rhap_progress-section {
    display: none;
  }
}
@media (min-width: 701px){

  .rhap_container {
    height: 100%;
  }
.rhap_main-controls{
  height: 100%;
  flex-grow:1;
  background-color: #1d1d1d;
}

.rhap_progress-container{
  position: absolute;
  width: calc( 100% - 300px );
  height: 8px;
  bottom: 0;
  right: 0;
  margin: 0;
}
  .rhap_progress-bar{
    height: 3px;
  }

}
@media (min-width: 1001px) {

  .rhap_progress-container {

    width: calc(100% - 400px);

  }
}
@media (max-width: 700px){

  .rhap_container {
    position: fixed;
    background-color: rgb(40, 40, 40);
    bottom:0;
    left:0;
    right:0;
    padding-top:0;

  }
  .rhap_main{
    position: relative;
  }
  .rhap_main-controls {
    padding-right: 20px;
  }
  .rhap_stacked .rhap_controls-section{
    flex-direction: column;
    justify-content: flex-start;
  }

  .rhap_progress-container{
      position: absolute;
      width: 100%;
      top:0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
      align-items: flex-start;

    .rhap_progress-bar{
      background-color:  rgba(255, 255, 255, 0.2);
      height: 2px;
    }

    .rhap_progress-filled{

    }

    .rhap_progress-indicator{
      display: none;

    }
  }

}

