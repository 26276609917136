
body{
  margin:0; padding:0;height: 100vh;
}
.App {
  min-height: 100vh;
}
*{
  box-sizing: border-box;
}

.bg-dark-gradient {
  background: #000!important;
  background: -webkit-linear-gradient(legacy-direction(0deg),#000,rgba(202,31,64,1))!important;
  background: linear-gradient(0deg,#000, rgba(202,31,64,1))!important;
}

.notif-icon {
  display: inline-flex;
  width: 40px;
  height: 40px;
  margin: 10px 10px 10px 0;
  align-items: center;
  justify-content: center;
  background: #eee;
  border-radius: 50%;
}
.notif-icon .fa {
  font-size: 15px;
}
.notif-icon.notif-danger {
  background: #f3545d!important;
}
.notif-icon.notif-success {
  background: #35cd3a!important;
}


.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  padding-top: 56.25%;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.pdf-viewer{
  height: 100vh;
}

.owl-carousel .owl-stage-outer {
  text-align: center;

}

.owl-carousel .owl-stage-outer > .owl-stage {
  display: flex;
  align-items: flex-end;
}
