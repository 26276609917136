$rhap_theme-color: opacify(#fff,0.87) !default;   // Color of all buttons and volume/progress indicators
$rhap_background-color: #000 !default; // Color of the player background
$rhap_bar-color: #e4e4e4 !default;     // Color of volume and progress bar
$rhap_time-color: #eee !default;       // Font color of current time and duration
$rhap_font-family: inherit !default;   // Font family of current time and duration



$color-base:            #869791 !default;
$color-white:           #FFF !default;
$color-gray:            #D6D6D6 !default;
$color-blue:            #007BFF !default;


//dots

$dot-width:             10px !default;
$dot-height:            10px !default;
$dot-rounded:           30px !default;
$dot-margin:            5px 7px !default;
$dot-background:        $color-gray !default;
$dot-background-active: $color-blue !default;


